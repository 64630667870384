import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const styleProps = {
  size: "text-sm",
  leading: "leading-5",
  tracking: "tracking-tight",
};

export interface BodySmProps extends TextInternalProps {
  "data-testid"?: string;
}

export const BodySm = ({
  color,
  textTransform,
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
  as = "p",
  ...otherProps
}: BodySmProps) => {
  const props = {
    ...styleProps,
    color,
    textTransform,
    truncate,
    ...otherProps,
  };
  return (
    <Text_INTERNAL dataTestId={dataTestId} as={as} {...props}>
      {children}
    </Text_INTERNAL>
  );
};
