import classNames from "classnames";
import { ToastMessage } from "./ToastMessage";
import { useToast } from "./ToastProvider";
import { placements } from "./constants";

export const ToastContainer = () => {
  const { toasts, placement, remove } = useToast();

  const isPlacementTop = placement === placements.top;
  const isPlacementBottom = placement === placements.bottom;

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(
        "fixed left-0 flex w-full items-center justify-center",
        "z-[9999]", // Same as Toast V1 for backwars compatibility
        {
          "top-0": isPlacementTop,
          "bottom-0": isPlacementBottom,
        },
      )}
    >
      <div
        className={classNames("absolute flex flex-col gap-2", {
          "top-4": isPlacementTop,
          "bottom-8": isPlacementBottom,
        })}
      >
        {toasts.map(({ id, ...args }) => (
          <ToastMessage
            id={id}
            key={id}
            onRemove={() => remove(id)}
            placement={placement}
            {...args}
          />
        ))}
      </div>
    </div>
  );
};
