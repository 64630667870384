import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const FONT_WEIGHTS = ["medium", "semibold", "bold"];

export const styleProps = {
  size: "text-sm",
  leading: "leading-5",
  tracking: "tighter",
};

export interface LabelSmProps extends TextInternalProps {
  "data-testid"?: string;
}

export const LabelSm = ({
  color,
  weight = "normal",
  truncate,
  [DATA_TESTID]: dataTestId,
  textTransform,
  children,
  as = "span",
}: LabelSmProps) => {
  const props = {
    ...styleProps,
    color,
    weight: `font-${weight}`,
    truncate,
    dataTestId,
    textTransform,
  };

  return (
    <Text_INTERNAL as={as} {...props}>
      {children}
    </Text_INTERNAL>
  );
};
