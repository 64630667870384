import { useEffect, useRef, useState } from "react";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";

export interface PortalProps {
  children: ReactNode;
  selectorQuery?: string;
  element?: Element;
  className?: string;
}

export const Portal = ({
  children,
  selectorQuery,
  element,
  className,
}: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (element) {
      // create a portal to element
      ref.current = element;
    } else if (selectorQuery) {
      // create a portal to element with id
      ref.current = document.querySelector(selectorQuery);
    } else {
      // create a new element under body and create a portal to it.
      ref.current = document.createElement("div");
      if (className) {
        ref.current.classList.add(...className.split(" "));
      }
      ref.current.classList.add("react-portal");
      document.body.appendChild(ref.current);
    }
    setIsLoading(false);
    return () => {
      // remove the element if was created by this component
      if (ref.current && !selectorQuery && !element) {
        ref.current.remove();
      }
    };
  }, []);

  if (isLoading || !ref.current) {
    return null;
  }

  return <>{createPortal(children, ref.current)}</>;
};
