import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const styleProps = {
  size: "text-xs",
  leading: "leading-5",
  tracking: "tracking-tight",
};

export interface BodyXsProps extends TextInternalProps {
  "data-testid"?: string;
}

export const BodyXs = ({
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
  color,
  ...otherProps
}: BodyXsProps) => {
  const props = {
    ...styleProps,
    truncate,
    dataTestId,
    color,
    ...otherProps,
  };

  return (
    <Text_INTERNAL as="p" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
