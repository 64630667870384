import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const FONT_WEIGHTS = ["medium", "semibold", "bold"];

export const styleProps = {
  size: "text-base",
  leading: "leading-5",
  tracking: "tighter",
};

export interface LabelMdProps extends TextInternalProps {
  "data-testid"?: string;
}

export const LabelMd = ({
  weight = "normal",
  color,
  truncate,
  [DATA_TESTID]: dataTestId,
  textTransform,
  children,
  as = "span",
}: LabelMdProps) => {
  const props = {
    ...styleProps,
    dataTestId,
    weight: `font-${weight}`,
    color,
    truncate,
    textTransform,
  };

  return (
    <Text_INTERNAL as={as} {...props}>
      {children}
    </Text_INTERNAL>
  );
};
