import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const FONT_WEIGHTS = ["medium", "semibold"];

export const styleProps = {
  size: "text-lg",
  leading: "leading-6",
  tracking: "tightest",
};

export interface LabelLgProps extends TextInternalProps {
  "data-testid"?: string;
}

export const LabelLg = ({
  weight = "normal",
  color,
  truncate,
  textAlign = "",
  [DATA_TESTID]: dataTestId,
  children,
}: LabelLgProps) => {
  const props = {
    ...styleProps,
    dataTestId,
    weight: `font-${weight}`,
    color,
    truncate,
    textAlign,
  };

  return (
    <Text_INTERNAL as="span" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
