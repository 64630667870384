import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { noop, uniqueId } from "lodash";
import { Portal } from "@circle-react-uikit/Portal";
import { ToastContainer } from "./ToastContainer";
import { placements, variants } from "./constants";
import type { Toast, ToastOptions, ToastPlacement } from "./interfaces";
import type { ToastCustomProgress } from "./toastCustomProgressEmitter";
import { toastCustomProgressEmitter } from "./toastCustomProgressEmitter";

export interface ToastContextProps {
  toasts: Toast[];
  show: (message: string, options?: ToastOptions) => string;
  success: (message: string, options?: ToastOptions) => void;
  error: (message: string, options?: ToastOptions) => void;
  remove: (id: string) => void;
  placement: ToastPlacement;
}

const ToastContext = createContext<ToastContextProps>({
  toasts: [],
  show: () => "",
  remove: noop,
  success: noop,
  error: noop,
  placement: placements.bottom,
});
ToastContext.displayName = "ToastContext";

export const useToast = () => useContext<ToastContextProps>(ToastContext);

export interface ToastProviderProps {
  defaultOptions?: ToastOptions;
  children: ReactNode;
}

export const ToastProvider = ({
  children,
  defaultOptions = {},
}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [placement, setPlacement] = useState<ToastPlacement>(placements.bottom);

  const show = useCallback(
    (message: string, options?: ToastOptions) => {
      const toastOptions = { ...defaultOptions, ...options };
      const { id, placement: placementProp = placements.bottom } =
        toastOptions || {};
      const placement = placements[placementProp];

      const newItem: Toast = {
        id: id ?? uniqueId(),
        message,
        ...toastOptions,
      };

      setPlacement(placement);
      setToasts(prevState => [...prevState, newItem]);

      return newItem.id;
    },
    [defaultOptions],
  );

  const success = (message: string, options?: ToastOptions) =>
    show(message, { ...options, variant: variants.success });

  const error = (message: string, options?: ToastOptions) =>
    show(message, { ...options, variant: variants.danger });

  const remove = (id: string) => {
    setToasts(prevState => prevState.filter(e => e.id != id));
  };

  useEffect(() => {
    const handleCustomProgress = ({
      toastId,
      customProgress,
      message,
      shouldHideToast = false,
    }: ToastCustomProgress) => {
      setToasts(prevState => {
        const toast = prevState.find(toast => toast.id === toastId);
        if (!toast) {
          return prevState;
        }
        const newToast = {
          ...toast,
          progress: customProgress ?? toast.progress,
          message: message ?? toast.message,
          shouldHideToast,
        };
        return [...prevState.filter(e => e.id !== toastId), newToast];
      });
    };

    toastCustomProgressEmitter.on("onCustomProgress", handleCustomProgress);

    return () => {
      toastCustomProgressEmitter.off("onCustomProgress", handleCustomProgress);
    };
  }, []);

  window.onViewOnlyModeError = error;

  return (
    <ToastContext.Provider
      value={{
        toasts,
        show,
        success,
        error,
        remove,
        placement,
      }}
    >
      <Portal>
        <ToastContainer />
      </Portal>
      {children}
    </ToastContext.Provider>
  );
};
