import { forwardRef } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";

export const DATA_TESTID = "data-testid";

export interface TextInternalProps {
  as?: any;
  color?: string;
  size?: string;
  weight?: string;
  leading?: string;
  tracking?: string;
  textTransform?: string;
  textAlign?: string;
  dataTestId?: string;
  truncate?: boolean;
  children?: ReactNode;
}

export const Text_INTERNAL = forwardRef(
  (
    {
      as = "span",
      color = "",
      size = "text-base",
      weight = "font-normal",
      leading = "leading-normal",
      tracking = "tracking-normal",
      textTransform = "normal-case",
      textAlign = "",
      truncate,
      dataTestId,
      children,
    }: TextInternalProps,
    ref: any,
  ) => {
    const colorClass = !color ? "text-dark" : color;
    const className = `${size} ${weight} ${leading} ${tracking} ${textTransform} ${textAlign} ${colorClass}`;
    const Component = as;

    const props = {
      className: classNames(className, { truncate: truncate }),
      ...(dataTestId && { [DATA_TESTID]: dataTestId }),
    };

    return (
      <Component ref={ref} {...props}>
        {children}
      </Component>
    );
  },
);
Text_INTERNAL.displayName = "Text";
