export const variants = {
  success: "success",
  danger: "danger",
} as const;

export const placements = {
  top: "top",
  bottom: "bottom",
} as const;

export const durations = {
  shortest: 500,
  short: 1500,
  default: 3000,
  long: 6000,
} as const;
