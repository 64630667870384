import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const FONT_WEIGHTS = ["medium", "semibold"];

export const styleProps = {
  size: "text-xs-plus",
};

export interface LabelXsPlusProps extends TextInternalProps {
  "data-testid"?: string;
}

export const LabelXsPlus = ({
  weight = "normal",
  color,
  textTransform,
  truncate,
  as = "span",
  [DATA_TESTID]: dataTestId,
  children,
}: LabelXsPlusProps) => {
  const props = {
    ...styleProps,
    dataTestId,
    weight: `font-${weight}`,
    color,
    textTransform,
    truncate,
  };

  return (
    <Text_INTERNAL as={as} {...props}>
      {children}
    </Text_INTERNAL>
  );
};
