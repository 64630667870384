import { CustomProgressToast as CustomProgressToastComponent } from "./CustomProgressToast";
import {
  ToastProvider as ToastProviderComponent,
  useToast as useToastHook,
} from "./ToastProvider";
import { toastCustomProgressEmitter } from "./toastCustomProgressEmitter";

export const ToastProvider = ToastProviderComponent;
export const useToast = useToastHook;

export const customProgressEmitter = toastCustomProgressEmitter;
export const CustomProgressToast = CustomProgressToastComponent;
