import PropTypes from "prop-types";
import { Icon } from "@circle-react-shared/Icon";
import { useDisableWindowScroll } from "@circle-react-shared/TrixEditor/useDisableWindowScroll";
import { Portal } from "@circle-react-uikit/Portal";

export const FullScreenLoader = ({ message = "" }) => {
  useDisableWindowScroll(true);

  return (
    <Portal className="react-loader react-fullscreen-loader">
      <div className="overlay">
        <div className="content">
          <Icon type="loader" />
          <div className="loader-message">{message}</div>
        </div>
      </div>
    </Portal>
  );
};

FullScreenLoader.propTypes = {
  message: PropTypes.string,
};
