import { BodyLg } from "./components/Body/BodyLg";
import { BodyMd } from "./components/Body/BodyMd";
import { BodySm } from "./components/Body/BodySm";
import { BodyXs } from "./components/Body/BodyXs";
import { LabelLg } from "./components/Label/LabelLg";
import { LabelMd } from "./components/Label/LabelMd";
import { LabelSm } from "./components/Label/LabelSm";
import { LabelXs } from "./components/Label/LabelXs";
import { LabelXsPlus } from "./components/Label/LabelXsPlus";
import { LabelXxs } from "./components/Label/LabelXxs";
import { TitleLg } from "./components/Title/TitleLg";
import { TitleMd } from "./components/Title/TitleMd";
import { TitleSm } from "./components/Title/TitleSm";
import { TitleXl } from "./components/Title/TitleXl";
import { TitleXxl } from "./components/Title/TitleXxl";

export const Typography = Object.assign(
  {},
  {
    TitleXxl,
    TitleXl,
    TitleLg,
    TitleMd,
    TitleSm,
    LabelLg,
    LabelMd,
    LabelSm,
    LabelXs,
    LabelXsPlus,
    LabelXxs,
    BodyLg,
    BodyMd,
    BodySm,
    BodyXs,
  },
);
