import PropTypes from "prop-types";
import { DefaultLoader } from "./DefaultLoader";
import { FullScreenLoader } from "./FullScreenLoader";
import "./styles.scss";

export const Loader = ({ variant = "default", show = true, ...rest }) => {
  if (!show) {
    return null;
  }
  const Component = variant === "fullscreen" ? FullScreenLoader : DefaultLoader;
  return <Component {...rest} />;
};

Loader.propTypes = {
  animate: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "fullscreen"]),
};
