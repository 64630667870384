export const animationsSlideUp = {
  enter: "transition ease-out duration-400 transform",
  enterFrom: "opacity-0 translate-y-8",
  enterTo: "opacity-100 -translate-y-0",
  leave: "transition ease-in duration-200 transform",
  leaveFrom: "opacity-100 -translate-y-0",
  leaveTo: "opacity-0 translate-y-8",
} as const;

export const animationsSlideDown = {
  ...animationsSlideUp,
  enterFrom: "opacity-0 -translate-y-8",
  enterTo: "opacity-100 translate-y-0",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-8",
} as const;
