import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";

export const DefaultLoader = ({ className = "", center = false }) => (
  <div
    className={classNames(
      "react-loader default-loader",
      {
        "m-auto": center,
      },
      className,
    )}
  >
    <Icon type="loader" />
  </div>
);

DefaultLoader.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
};
