import PropTypes from "prop-types";
import { sanitize } from "dompurify";
import isString from "lodash/isString";

export const SanitizeHTMLString = ({
  content,
  sanitizeProps,
  as = "span",
  className,
  dataTestId,
}) => {
  if (!content || !isString(content)) {
    return null;
  }

  const clean = sanitize(content, {
    ...sanitizeProps,
  });

  const Component = as;

  return (
    <Component
      className={className}
      dangerouslySetInnerHTML={{ __html: clean }}
      data-testid={dataTestId}
    />
  );
};

SanitizeHTMLString.propTypes = {
  content: PropTypes.string,
  sanitizeProps: PropTypes.object,
  as: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
