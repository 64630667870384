import PropTypes from "prop-types";
import classNames from "classnames";

export const CircularProgress = ({
  percentage,
  size = 16,
  strokeWidth = 3,
  backgroundCircleClassName,
  foregroundCircleClassName,
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (size - strokeWidth) / 2;
  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        className={classNames("text-lightest", backgroundCircleClassName)}
        opacity={0.5}
        strokeWidth={strokeWidth}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className={classNames("text-light", foregroundCircleClassName)}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
};

CircularProgress.propTypes = {
  percentage: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  backgroundCircleClassName: PropTypes.string,
  foregroundCircleClassName: PropTypes.string,
};
